@import '/node_modules/bootstrap/dist/css/bootstrap.css';

@import './css/apex-charts.css';
@import './css/app-chat.css';
@import './css/core.css';
@import './css/core-dark.css';
@import './css/fontawesome.css';
@import './css/tabler-icons.css';
@import './css/theme-default.css';
@import './css/theme-default-dark.css';
@import './css/page-auth.css';
@import './css/perfect-scrollbar.css';

/* img */

.logo-img{ 
  margin: 10px;
  width: 100px;
}
.telega-img{
  width: 125px;
  margin: 0 15px;
}

/* header */

.bg-menu-theme.menu-vertical .menu-item.active > .menu-link:not(.menu-toggle){
  background: rgba(134, 146, 208, 0.18);
  box-shadow: none;
}

/* input */

.codeinput .code{
  border: 1px solid #6f65d6!important;
  color: #fff!important;
  background: rgba(92, 90, 90, 0) !important;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  margin-right: 10px;
  text-align: center;
  outline: none!important;
}
.codeinput{
  margin-bottom: 20px;
}
input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: #333 !important;
  box-shadow: 0 0 0 #fff !important;
  color: #fff !important;
}

/* chat */

.chat-contact-list-item:hover{
  background-color: #25293c;
}
.chat-contact-list-item.open{
  background-color: #25293c;
  border: solid 1px #6f65d6;
}
.chat_container{
  height: calc(100vh - 10rem)!important;
  overflow-y: scroll!important;
}
.drop-menu-chat{
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(0px, 20px);
}
.app-chat .app-chat-contacts{
  flex-basis: auto;
}
.avatar.avatar-text {
  width: 3rem;
  height: 3rem; 
}
.non-style{
  background: none;
  border: none;
}

/* modal */

.modal-style{
  display: block;
  padding-left: 0px; 
  background: rgba(37, 41, 60, 0.78);
}
.btn-x {
  box-sizing: content-box;
  width: 1.125rem;
  height: 1.125rem;
  padding: 0.25em 0.25em;
  color: #fff;
  background: transparent url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 4.5L5 13.5' stroke='%23fff' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 4.5L5 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='%23fff' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") center/1.125rem auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.modal .btn-x {
  background-color: #2f3349;
  border-radius: 0.375rem;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 4.5L5 13.5' stroke='%237983bb' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 4.5L5 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='%237983bb' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  opacity: 1;
  padding: 0.44rem;
  box-shadow: 0 0.125rem 0.25rem rgba(15, 20, 34, 0.4);
  transition: all 0.23s ease 0.1s;
  transform: translate(23px, -25px);
}
.modal-simple .btn-x {
  position: absolute;
  top: -2rem;
  right: -2rem;
}
.color-modal{
  color: #cfd3ec;
}
.comment{
  background: #7367f0;
  border-radius: 0 10px 10px 10px;
  padding: 15px 20px;
}

/* table */

.border-table{
  border: 1px solid #7983bb30;
  border-radius: 10px;
  padding: 10px 20px 10px 10px;
}

/* notification */

.notificationCard {
  background-color: rgba(19, 20, 41, 0.88);
  border: 2px solid;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
}
.notificationMessg {
  text-transform: none;
  font-size: 15px;
}
.notificationTitle {
  font-size: 14px;
  color: rgb(232, 230, 230);
}
.notificationCard.danger {
  border-color: #ea5455;
  background-color: #3e364a;
}
.notificationCard.success {
  border-color: #28c76f;
  background-color: #2e3f4c;
}
.notificationCard.warning {
  border-color: #ff9f43;
  background-color: #403c49;
}
.danger .notificationMessg{
  color: #ea5455;
}
.success .notificationMessg{
  color: #28c76f;
}
.warning .notificationMessg{
  color: #ff9f43!important;
}